<template>
     <div class="main">
          <div style="text-align: center;width:100%;">
               <h1>Improving story-telling one slide at a time</h1>
               <h4 class=" worksans">
                    Slyde.ai makes your content consumable, accessible, and aesthetic.
               </h4>
               <div class="video ">
                    <div class="embed-container">
                         <iframe src="https://www.youtube.com/embed/NBLO6RpofIU" frameborder="0" allowfullscreen></iframe>
                    </div>
               </div>
               <div class="emailcontainer">
                    <img class="circle" src="@/assets/images/Vector4.png" alt="" />
                    <img class="dots" src="@/assets/images/Vector5.png" alt="" />
                    <div class="email ">
                         <h3 class="mb-5 worksans ">Get notified when we launch !</h3>
                         <div class="columns is-gapless is-mobile worksans">
                              <div class="column  ">
                                   <b-input v-model="email" class="emailinput" type="email" placeholder="richard@piedpiper.com" @click="subscribe">
                                   </b-input>
                              </div>
                              <div class="column is-4" style="">
                                   <div style="padding-left:10px;"><button class="button" @click="subscribe">Notify me</button></div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <!-- <b-button @click="clickMe" class="coral"> Click Me</b-button>
          <b-button type="is-coral" class="worksans">Primary</b-button> -->
     </div>
</template>

<script>
     import axios from 'axios';
     export default {
          data() {
               return {
                    email: '',

                    reg: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
               };
          },
          methods: {
               subscribe() {
                    if (!this.isEmailValid()) {
                         this.processError('Please enter a valid email');
                         return;
                    }

                    this.sendRequest(this.email, this.getTheme());
               },

               async sendRequest(email, theme) {
                    console.log(email, theme);

                    try {
                         const response = await axios.get('https://api.slyde.ai/subscribe', {
                              params: { email: email, theme: theme },
                         });

                         if (!response || response.status !== 200) {
                              this.processError('Error');
                              return;
                         }

                         let message = '';
                         if (response.data == 'Subscribed') {
                              message = 'Subscribed!';
                         } else if (response.data == 'Already subscribed') {
                              message = ' You have already subscibed!';
                         }

                         this.$buefy.toast.open({
                              message: message,
                              position: 'is-bottom',
                              type: 'is-success',
                              duration: 4000,
                         });
                    } catch (e) {
                         this.processError('Network error, Try again later');
                    }
               },
               getTheme() {
                    const theme = this.$store.state.isdarkmode ? 'dark' : 'light';
                    return theme;
               },
               isEmailValid: function() {
                    return this.email == '' ? '' : this.reg.test(this.email) ? true : false;
               },
               processError(errorMessage) {
                    this.$buefy.toast.open({
                         message: errorMessage,
                         position: 'is-bottom',
                         type: 'is-danger',
                         duration: 3000,
                    });
               },
          },
     };
</script>

<style lang="scss" scoped>
     @import '@/assets/styles/variables.scss';

     .darkmode {
          .email {
               background: $bg_secondary_dark !important;
               box-shadow: 0px 6.26856px 25.0742px $shadow_dark !important;
          }
     }
     .video {
          margin: auto;
          padding: 0 40px;
          max-width: 800px;
          text-align: center;
          margin-top: 50px;
     }
     .emailcontainer {
          position: relative;
          margin: auto;
          margin-top: 70px;

          max-width: 500px;
          margin-bottom: 70px;
          padding: 7px;

          .dots {
               position: absolute;
               bottom: -35px;
               right: -25px;
               width: 60px;
               z-index: 10;
          }
          .circle {
               display: none;
               position: absolute;
               top: -25px;
               left: -35px;
               width: 60px;
               z-index: 1;
          }

          .email {
               transition: all 0.8s;
               width: 100%;
               background: $bg_secondary_light;
               box-shadow: 0px 6.26856px 25.0742px $shadow_light;
               border-radius: 3.76114px;
               padding: 30px 30px 40px 30px;

               z-index: 5;

               .emailinput {
                    width: 100%;
                    display: inline-block;
               }
               button {
                    box-sizing: border-box;
                    border: none;
                    color: white;

                    width: 100%;
                    height: 47px;
                    font-size: 15px;
                    background: $primary;
                    border-radius: 3px;
               }
          }
     }
     .embed-container {
          border-radius: 10px;
          position: relative;
          padding-bottom: 56.25%;
          height: 0;
          overflow: hidden;
          max-width: 100%;
     }
     .embed-container iframe,
     .embed-container object,
     .embed-container embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
     }

     @media only screen and (max-width: 600px) {
          .main {
               padding: 10px;
          }
          h1 {
               font-size: 28.329px;
          }

          h4 {
               font-size: 15.8125px;
          }
          .video {
               margin-top: 30px;
               padding: 0 13px;
          }

          .emailcontainer {
               position: relative;
               margin: auto;
               margin-top: 40px;
               margin-bottom: 45px;

               .dots {
                    position: absolute;
                    bottom: -15px;
                    right: -10px;
                    width: 40px;
                    z-index: 10;
               }
               .circle {
                    display: none;
                    position: absolute;
                    top: -25px;
                    left: -35px;
                    width: 60px;
                    z-index: 1;
               }
               .email {
                    padding: 30px 14px 40px;

                    h3 {
                         font-weight: 600;
                         font-size: 18.28px;
                    }
               }
          }
     }
</style>
<style lang="scss">
     .emailinput {
          input {
               height: 47px !important;
          }
     }
</style>
